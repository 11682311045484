<template>
  <div id="Information" class="q-pa-md q-gutter-sm">
    <!-- 面包屑 -->
    <q-breadcrumbs>
      <q-breadcrumbs-el icon="iconfont icon-zhuye" label="主页" to="/orm/dashboard" />
      <q-breadcrumbs-el label="订单管理" icon="iconfont icon-dingdanguanli" />
      <q-breadcrumbs-el label="资料审核" icon="iconfont icon-ziliaoshenhe" to="/information/list" />
    </q-breadcrumbs>
    <div class="Informationbox" id="Informationbox">
      <!-- 搜索组件 -->
      <q-card class="my-card" style="margin-bottom: 10px;">
        <q-card-section>
          <div class="row" id="searchbox">
            <div class="col-9 search">
              <q-input class='operation' :dense='true' outlined  v-model="queryData.patientName" label="患者姓名">
                <template v-if="queryData.patientName" v-slot:append>
                  <q-icon name="close" @click.stop="queryData.patientName = ''" class="cursor-pointer" style="font-size: 16px;"/>
                </template>
              </q-input>
              <selectq class='operation' :options='informationStatus' :model.sync='checkStatus' :label='"资料审核状态"' />
              <el-button
                :disabled='Permissionslist.filter(item=>item==="information.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='search'
              >
              搜索
              </el-button>
              <el-button
                :disabled='Permissionslist.filter(item=>item==="information.list").length>0?false:true'
                class='resetquery'
                type="primary"
                size='medium'
                @click='chongzhi'
              >
              重置
              </el-button>
            </div>
            <div class="col  addbtn">
              <!-- :disabled='multipleSelection.length==0? true : false' -->
              <el-button
                :disabled='Permissionslist.filter(item=>item==="information.check").length>0?false:true'
                type="primary"
                size='medium'
                @click='Batchcheck'
              >
              审核通过
              </el-button>
              <el-button
                :disabled='Permissionslist.filter(item=>item==="information.check").length>0?false:true'
                type="primary"
                size='medium'
                @click='Batchreceive'
              >
              领取任务
              </el-button>
            </div>
          </div>
        </q-card-section>
      </q-card>
      <!-- 表格区 -->
      <q-card class="my-card">
        <!-- 筛选区域 -->
        <!-- <q-card-section class='tabletop'>
          
        </q-card-section> -->
        <q-card-section>
          <el-table
          v-loading="ordervisible"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(55,55,55,0.2)"
          ref="multipleTable"
          :data="informationlist"
          @sort-change="sortthiscolumn"
          tooltip-effect="dark"
          style="width: 100%"
          :max-height="tableHeight"
          @selection-change="handleSelectionChange"
          >
          <template slot="empty">
            {{nodatetype}}
          </template>
          <el-table-column align='center' type="selection" width="55" fixed="left"></el-table-column>
          <el-table-column v-if="orderID" sortable="custom" show-overflow-tooltip align='center' prop="orderId" label="订单号" min-width='93px'></el-table-column>
          <el-table-column v-if="patient" sortable="custom" align='center' prop="patientName" label="患者姓名" min-width='104px'></el-table-column>
          <!-- 加急订单 -->
          <el-table-column v-if="Urgent  && urgenthide" align='center' width='70px'>
            <template slot-scope="{row}">
              <el-tag v-if='row.urgentStatus' type="danger" style='padding:0 5px;'>加急</el-tag>
              <el-tag v-if='row.weekDay' type="danger" style='padding:0 5px;'>周末</el-tag>
            </template>
          </el-table-column>
          <!-- 主管医师 --> 
          <el-table-column v-if="attendingDoctor" sortable="custom" show-overflow-tooltip align='center' prop="attendingDoctor" label="主管医师" min-width='104px'></el-table-column>
          <el-table-column v-if="doctor" sortable="custom" align='center' prop="doctorName" label="医生姓名" min-width='104px'></el-table-column>
          <el-table-column v-if="createU" sortable="custom" show-overflow-tooltip align='center' prop="createUser" label="下单人"  min-width='93px'></el-table-column>
          <el-table-column v-if="province" sortable="custom" show-overflow-tooltip align='center' prop="provinceName" label="省份"></el-table-column>
          <el-table-column v-if="city" sortable="custom" show-overflow-tooltip align='center' prop="cityName" label="城市"></el-table-column>
          <el-table-column v-if="hos" sortable="custom" show-overflow-tooltip align='center' prop="hospName" label="医院"></el-table-column>
          <el-table-column v-if="depart" sortable="custom" show-overflow-tooltip align='center' prop="departName" label="科室"></el-table-column>
          <el-table-column v-if="combo" sortable="custom" show-overflow-tooltip align='center' prop="comboName" label="套餐"></el-table-column>
          <el-table-column v-if="Amt" align='center' sortable="custom" prop="orderAmt" label="订单金额" min-width='104px'></el-table-column>
          <el-table-column v-if="sTime" align='center' sortable="custom" prop="surgeryDate" label="手术时间" min-width='151px'></el-table-column>
          <el-table-column v-if="cTime" sortable="custom" align='center' prop="createTime" label="创建时间" min-width='151px'></el-table-column>
          <!-- 代理商是否收款 -->
          <el-table-column v-if="pay" sortable="custom" align='center' prop="isPay" label="代理商是否收款" min-width="143px" >
            <template slot-scope="scope">
              <q-chip v-show="scope.row.isPay!=null" square :color="scope.row.isPay==true?'positive':'warning'" text-color="white">
                {{ (isPay.filter(item=>item.key == scope.row.isPay).pop() || {} ).value }}
              </q-chip>
            </template>
          </el-table-column>
          <!-- 订单类型 -->
          <el-table-column v-if="orderT" sortable="custom" align='center' prop="orderType" label="订单类型" min-width="117px" >
            <template slot-scope="scope">
              <q-chip v-show="scope.row.orderType!=null" square :color="orderTypecolor[scope.row.orderType]" text-color="white">
                {{ (orderType.filter(item=>item.key == scope.row.orderType).pop() || {} ).value }}
              </q-chip>
            </template>
          </el-table-column>
          <!-- 支付状态 -->
          <el-table-column v-if="payS" sortable="custom" align='center' prop="payStatus" label="支付状态" min-width="100px" >
            <template slot-scope="payStatus">
              <q-chip square :color="payStatuscolor[payStatus.row.payStatus]" text-color="white">
                {{ payStatus.row.payStatus==0? '待支付':'已支付' }}
              </q-chip>
            </template>
          </el-table-column>
          <!-- 审核状态 -->
          <el-table-column v-if="informationS" sortable="custom" align='center' prop="informationStatus" label="审核状态" min-width="117px">
            <template slot-scope="Types">
              <q-chip square :color="checkStatuscolor[Types.row.informationStatus]" text-color="white">
                {{ (informationStatus.filter(item => item.key == Types.row.informationStatus ).pop() || {}).value }}
              </q-chip>
            </template>
          </el-table-column>
          <el-table-column align='center' width="300" fixed="right">
            <template slot="header">
              <div class='newCaozuobox'>
                <div class='czTit'>操作</div>
                <div class='czpopoverbtn'>
                  <el-popover
                    placement="bottom"
                    width="170"
                    trigger="click">
                      <q-checkbox v-model="orderID" label="订单号" color="cyan" />
                      <q-checkbox v-model="patient" label="患者姓名" color="cyan" />
                      <q-checkbox v-model="Urgent" label="订单加急" color="cyan" />
                      <q-checkbox v-model="attendingDoctor" label="主管医师" color="cyan" />
                      <q-checkbox v-model="doctor" label="医生姓名" color="cyan" />
                      <q-checkbox v-model="createU" label="下单人" color="cyan" />
                      <q-checkbox v-model="province" label="省份" color="cyan" />
                      <q-checkbox v-model="city" label="城市" color="cyan" style='margin-right: 8px;'/>
                      <q-checkbox v-model="hos" label="医院" color="cyan" style='margin-right: 8px;'/>
                      <q-checkbox v-model="depart" label="科室" color="cyan" />
                      <q-checkbox v-model="combo" label="套餐" color="cyan" style='margin-right: 8px;' />
                      <q-checkbox v-model="Amt" label="订单金额" color="cyan" />
                      <q-checkbox v-model="sTime" label="手术时间" color="cyan" />
                      <q-checkbox v-model="cTime" label="创建时间" color="cyan" />
                      <q-checkbox v-model="pay" label="代理商是否收款" color="cyan" />
                      <q-checkbox v-model="orderT" label="订单类型" color="cyan" />
                      <q-checkbox v-model="payS" label="支付状态" color="cyan" />
                      <q-checkbox v-model="informationS" label="审核状态" color="cyan" />
                    <el-button icon="el-icon-s-grid" size='medium' slot="reference" style="padding: 5px 10px;border: none;">
                      <i class="el-icon-caret-bottom"></i>
                    </el-button>
                  </el-popover>
                </div>
              </div>
            </template>
           
            <template slot-scope="scope">
              <!-- 领取 -->
              <q-btn  
                :disable='Permissionslist.filter(item=>item==="information.check").length>0 && scope.row.informationStatus==0? false : true'
                class="czbtn" 
                size="sm" 
                color="blue" 
                icon="iconfont icon-lingqu" 
                @click='Checkclaim(scope.row)'>
                <q-tooltip content-class="bg-blue" :offset="[10, 10]">
                  领取
                </q-tooltip>  
              </q-btn> 
              <!-- 审核@click='Checkagree' -->
              <q-btn
                :disable='Permissionslist.filter(item=>item==="information.check").length>0 && scope.row.informationStatus!=0? false : true'
                class="czbtn"  
                size="sm" color="green-5" 
                icon="iconfont icon-dingdanshenhe" 
                @click='Checkagree(scope.row)'
              >
                <q-tooltip content-class="bg-green-5" :offset="[10, 10]">
                  审核
                </q-tooltip>  
              </q-btn>
              <!-- 放弃@click='Checkagree' -->
              <q-btn
                :disable='Permissionslist.filter(item=>item==="information.check").length>0 && scope.row.informationStatus==1? false : true'
                class="czbtn"  
                size="sm" color="yellow-7" 
                icon="iconfont icon-icon_function_fangqi" 
                @click='fangqi(scope.row)'
              >
                <q-tooltip content-class="bg-yellow-7" :offset="[10, 10]">
                  放弃
                </q-tooltip>  
              </q-btn>
              <!-- 资料下载 -->
              <!-- <q-btn size="sm" color="yellow-7" icon="iconfont icon-xiazai">
                <q-tooltip content-class="bg-yellow-7" :offset="[10, 10]">
                  资料下载
                </q-tooltip>
              </q-btn> -->
            </template>
          </el-table-column>
          
        </el-table>
         <!-- 分页组件 -->
        <elementPagination class="Paginationstyle" :total='total' :queryData='queryData' @getList='getinformationCheckList()' @changepageSize='changepageSize' @changePage='changePage'/>
        </q-card-section>
      </q-card>

      <!-- 资料审核组件 -->
      <el-dialog :visible.sync="agreeconfirm" width="70%" title="资料审核" :close-on-click-modal='false' @close="agreeconfirm = false">
        <checktask
          v-if="agreeconfirm"
          class="el-dialog__body"
          ref='checkagree'
          :checkAlllist.sync='checkAlllist'
          :checkAllData.sync='agreeAllData'
          :checkData.sync='agreeData'
          @closedialog='agreeconfirm = false'
          @checksuccess='getinformationCheckList()'
        >
        </checktask>
      </el-dialog> 

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import selectq from '@/components/Order/select/index2.vue' // 下拉框组件
import elementPagination from '@/components/ElementUI/elementPagination.vue' // 分页组件
import checktask from '@/components/information/informationagree.vue' // 审核任务组件
import { makeDebounce } from "@/utils/debounce.js";  
import { getprovince,getcity,gethosplist,getdepart,getcombo,getoptions,optionget } from '@/api/components/checkcitys.js'; // 获取城市接口

import { orderCheck,informationCheckList,informationCheckclaim,informationCheckagree,CheckdownloadList,informationResign } from '@/api/Information.js'; // 用户订单增删改查接口
import { getHeight } from '@/api/getHeight.js'; // 获取列表最高高度
import jwt from "jsonwebtoken";
import {
  getItem,
} from '@/utils/storage.js'
import { notifys } from '@/api/components/Notify.js'

export default {
  created(){
    this.getinformationCheckList()
    document.onkeydown=e=>{
      let _key = window.event.keyCode;
      if(_key===13){
        this.search()
      }  
    }
  },
  data() {
    return {
      tableHeight:200, // 列表最大高度

      ordervisible: false, //控制表格loading
      nodatetype:'', //无内容时显示
      checkAlllist:{
        province:[],
        city:[],
        hosp:[],
        depart:[],
        combo:[],
      },
      checkStatuscolor:{ //资料状态
        0: 'primary', //资料待审核
        1: 'primary', //资料审核中
        2: 'negative', //资料被驳回
        3: 'indigo-5', //资料待重建
        4: 'indigo-5', //三维重建中
        5: 'indigo-5', //重建结果已上传
        6: 'indigo-5', //重建结果待审核
        7: 'indigo-5', //重建结果审核中
        8: 'negative', //重建结果被驳回
        9: 'positive', //重建完成
      },
      orderTypecolor:{
        0: 'positive',
        1: 'primary',
      },
      payStatuscolor:{ //支付状态
        0: 'primary',
        1: 'positive',
      },
      // 分页设置
      queryData:{ // element分页信息
        page: 1,
        pageSize: 10,
        patientName:'', // 患者名称
        // orderId: '', // 订单号
      },
      sortField:'', // 控制那列排序
      sortType:'', // 控制排序类型

      checkStatus:'', //订单审核状态
      total:0, //总条数

      patient: true, //控制患者列
      hos: true, //控制医院
      combo: true, //控制套餐列
      cTime: true, //控制创建时间 
      informationS: true, //控制审核状态
      Urgent: true, //控制订单加急

      attendingDoctor:false, //主管医师
      payS: false, //控制支付状态
      doctor: false, //控制医生列
      depart: false, //控制科室
      Amt: false, //控制金额
      orderID: false, //控制订单号
      createU: false, // 控制下单人
      orderT: false, // 控制订单类型
      province: false, //控制省份
      city: false, // 控制城市
      sTime: false, //控制手术时间
      pay: false, //控制代理商是否收款



      informationlist:[], //资料审核列表数据
      urgenthide: false,//是否展示加急列
      multipleSelection:[], //列表复选框选择的值

      agreeconfirm: false, //控制审核
      agreeAllData:null, // 查看订单的总信息
      agreeData:{ // 查看用户所需数据
        provinceoptions: null, // 省份所选项
        cityoptions: null, // 城市所选项
        hospoptions: null, // 医院所选项所选项
        departoptions: null, // 科室所选项
        combooptions: null, // 套餐所选项
        checkReportFilesList:[],//检查报告资料
        dcmReportFilesList:[], //影像资料报告
        informationCheckId:'', // 审核任务id
        disable: null, //控制是否可以驳回和审核通过
        reject:'', // 资料驳回信息

        returnedAmtDisable: null, //控制是否显示回款金额
        userType:'', //用户类型
        discountCode:'', //暂时的优惠码
        discountPercent:'', //折扣力度
        urgentAmt:'', //加急金额
        orderReturnedAmt:'', //回款金额
      },

    };
  },
  components:{
    elementPagination, //分页组件
    checktask, //资料审核页面
    selectq,
  },
  computed:{
  ...mapState([
      'informationStatus', // 资料审核状态
      // 'orderCheckStatus', //订单审核状态
      'orderType', //订单类型
      'orderStatus', //订单状态
      'orderType',//订单类型
      'payStatus', // 支付状态
      'isPay', //代理商付款状态
      'Permissionslist' // 权限词典
    ]),
  },
  watch:{
  },
  methods: {
    // setTimeout(async() =>{},500)
    getinformationCheckList(ordername,sortType){ //获取资源审核列表
      this.nodatetype=''
      this.ordervisible = true
      this.urgenthide = false //默认隐藏加急列

      setTimeout(async() =>{
        const { data: res } = await informationCheckList({
          ...this.queryData,
          informationStatus: this.checkStatus.key,
          sortField: ordername, //给哪一列排序
          sortType: sortType, //排序类型升序或降序
        })
        console.log(res);
        if(res.code===20000){
          this.informationlist = res.data.orders
          this.informationlist.map((e)=>{
            if(e.urgentStatus || e.weekDay ){
              //有加急订单 或者是有 周末订单
              this.urgenthide = true //显示加急列
            }
          })
          this.total = res.data.totals * 1
        }
        
        let height = document.getElementById('searchbox').offsetHeight + 60
        this.tableHeight = getHeight('Informationbox',height)

        this.ordervisible = false
        this.nodatetype='暂无数据'
      },500)
      
    },
    sortthiscolumn(column){
      console.log(column);
      console.log(column.order);
      if(column.order=='ascending'){
        
        if(column.prop=='provinceName'){
          this.getinformationCheckList('provinceId',1)
          this.sortField = 'provinceId'
          this.sortType = 1
        }else if(column.prop=='cityName'){
          this.getinformationCheckList('cityId',1)
          this.sortField = 'cityId'
          this.sortType = 1
        }else if(column.prop=='hospName'){
          this.getinformationCheckList('hospId',1)
          this.sortField = 'hospId'
          this.sortType = 1
        }else if(column.prop=='comboName'){
          this.getinformationCheckList('comboId',1)
          this.sortField = 'comboId'
          this.sortType = 1
        }else if(column.prop=='departName'){
          this.getinformationCheckList('departId',1)
          this.sortField = 'departId'
          this.sortType = 1
        }else{
          // ascending 为升序
          this.getinformationCheckList(column.prop,1)
          this.sortField = column.prop
          this.sortType = 1
        }
        
      }else if(column.order=='descending'){

        if(column.prop=='provinceName'){
          this.getinformationCheckList('provinceId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else if(column.prop=='cityName'){
          this.getinformationCheckList('cityId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else if(column.prop=='hospName'){
          this.getinformationCheckList('hospId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else if(column.prop=='comboName'){
          this.getinformationCheckList('comboId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else if(column.prop=='departName'){
          this.getinformationCheckList('departId',0)
          this.sortField = 'provinceId'
          this.sortType = 0
        }else{
          // ascending 为降序
          this.getinformationCheckList(column.prop,0)
          this.sortField = column.prop
          this.sortType = 0
        }
        
      }else{
        // 默认按照时间排序排序
        this.getinformationCheckList('createTime',0)
        this.sortField = 'createTime'
        this.sortType = 0
      }
    },
    //批量领取
    Batchreceive(){
      const data = this.multipleSelection.map(item=>{
        return item.informationCheckId
      })
      console.log(data);
      this.$q.dialog({
        title: '批量领取',
        message: `你确定要领取已勾选的资料审核任务吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const { data: res } = await informationCheckclaim({ ids: data.join(',') })
            console.log(res);
            if(res.code===20000){
              this.getinformationCheckList()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
              console.log(e);
          }
      })
    },
    // 批量审核
    Batchcheck(){ 
      const data = this.multipleSelection.map(item=>{
        return item.informationCheckId
      })
      console.log(data);
      this.$q.dialog({
        title: '批量审核',
        message: `你确定要让已勾选的资料审核任务全部通过吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const { data: res } = await informationCheckagree({ ids: data.join(',') })
            console.log(res);
            if(res.code===20000){
              this.getinformationCheckList()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
              console.log(e);
          }
      })
    },
    // 单个领取
    async Checkclaim(row){
      console.log(row);
      this.$q.dialog({
        title: '领取',
        message: `你确定要领取此资料审核任务吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
            const { data: res } = await informationCheckclaim({ ids: row.informationCheckId })
            console.log(res);
            if(res.code===20000){
              this.getinformationCheckList()
              notifys('positive',res.message)
            }else{
              notifys('negative',res.message)
            }
          }catch (e){
              console.log(e);
          }
      })
    },
    //点击放弃
    async fangqi(row){
      this.$q.dialog({
        title: '放弃资料审核',
        message: `你确定要放弃此资料审核任务吗?`,
        ok: { label: '确定'},
        cancel: { label: '取消',flat: true},
      }).onOk(async() => {
        try{
          const { data: res } = await informationResign({id: row.informationCheckId})
          console.log(res);
          if(res.code===20000){
            this.getinformationCheckList()
            notifys('positive',res.message)
          }else{
            notifys('negative',res.message)
          }
        }catch (e){
          console.log(e);
        }
      })
    },
    //点击审核时
    Checkagree:makeDebounce(async function(row){
      const token = getItem('token');
      this.agreeData.userType = jwt.decode(token).userType
      // console.log(row);
      this.agreeData.informationCheckId = row.informationCheckId
      const {data:res} = await orderCheck({orderId:row.orderId})
      console.log(res);
      if(res.code===20000){
        this.agreeAllData = res.data.order //储存总数据
        console.log(this.agreeAllData);
        this.agreeData.disable = this.agreeAllData.reconstructionVo.informationStatus == 1? false: true;
        // 控制是否显示回款金额 只有商务和系统管理员可以显示回款金额
        this.agreeData.returnedAmtDisable = this.agreeData.userType ==2 || this.agreeData.userType ==3 ? true : false, 

        // 回款金额
        this.agreeData.orderReturnedAmt = this.agreeAllData.orderReturnedAmt ? this.agreeAllData.orderReturnedAmt: ''
        // 加急金额
        this.agreeData.urgentAmt = this.agreeAllData.urgentAmt ? this.agreeAllData.urgentAmt: ''
        // 优惠码
        this.agreeData.discountCode = this.agreeAllData.discountCode ? this.agreeAllData.discountCode: ''
        //折扣力度
        this.agreeData.discountPercent = this.agreeAllData.discountPercent ? this.agreeAllData.discountPercent: ''

        console.log(this.agreeAllData);
        this.agreeData.checkReportFilesList = [] //清空检查报告
        this.agreeData.dcmReportFilesList =[] // 清空影像资料
        this.agreeconfirm = true

        // 获取资料驳回信息
        if(this.agreeAllData.reconstructionVo){
          this.agreeData.reject = `${this.agreeAllData.reconstructionVo.informationComments}`
        }
        // console.log(this.agreeAllData.informationList);
        if(this.agreeAllData.informationList){
          this.agreeAllData.informationList.map(item=>{
            if(item.informationType == 0){
              this.agreeData.checkReportFilesList.push(item)
            } else{
              this.agreeData.dcmReportFilesList.push(item)
            }
          })
        }
      }
    },300),
    // 复选框被选择时
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 点击重置
    chongzhi:makeDebounce(function(){
      this.queryData.page = 1
      this.queryData.patientName = ''
      this.checkStatus =''
      this.getinformationCheckList()
    },200),
    //点击搜索
    search:makeDebounce(function(){
      this.queryData.page = 1
      this.getinformationCheckList(this.sortField,this.sortType)
    },200),
    // 分页被修改时
    changepageSize(val){
      this.queryData.pageSize = val
      this.getinformationCheckList(this.sortField,this.sortType)
    },
    changePage(val){
      this.queryData.page = val
      this.getinformationCheckList(this.sortField,this.sortType)
    }
    
  },
};
</script>

<style lang='less' scoped>
#Information{
  height: 100%;
}
.Informationbox{
  height: 90%;
}
.search{
  display: flex;
  flex-direction: row ;
  align-items: flex-end;
  flex-wrap: wrap;
  .operation{
    margin: .625rem .625rem 0 .625rem;
    width: 12.5rem;
  }
}
.q-card__section--vert {
  padding: 10px;
}
/deep/.el-table td{
  padding: 8px 0 !important;
}
.newCaozuobox{
  // display: flex;
  // justify-content: space-between;
  .czTit{
    float: left;
    text-align: right;
    width:50%;
  }
  .czpopoverbtn{
    float: left;text-align: right;width:50%;
  }
}
.tabletop{
  display: flex;
  flex-direction:row-reverse;
  padding: .3125rem 1rem;
}
.addbtn{
  display: flex;
  flex-direction: row ;
  justify-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;
}
.resetquery{
  margin: .625rem .625rem 0 .625rem;
}
.czbtn{
  margin-right: .75rem;
}
.Paginationstyle{
  margin-top: .9375rem;
}
.upload{
  display: none;
}
/deep/.el-dialog__body{
  padding: 0 10px 10px !important;
}
</style>
